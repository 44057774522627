import { Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";

import { BeneficiariesService } from "../../shared/services/beneficiaries.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AddressValidator } from "src/validators/digital";
import { SpousalService } from "../../shared/services/spousal.service";
import { ChildrenService } from "../../shared/services/children.service";
import { DependantsService } from "../../shared/services/dependants.service";

@Component({
  selector: "app-other-beneficiary",
  templateUrl: "./other-beneficiary.component.html",
  styleUrls: ["./other-beneficiary.component.css"],
})
export class OtherBeneficiaryComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  address;
  isVertical = false;
  orientation: StepperOrientation = "vertical";
  smallScreen: boolean;
  completed: boolean = false;
  state: string;
  title = "htmltopdf";
  anothername: string;
  residential: string;
  child_address;
  person_org = ''
  listProp = "no"

  showSave = "yes";

  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;

  first_name = ''
  mid_name = ''
  last_name = ''
  relationship = ''
  res_address = ''
  mail_address = ''
  digi_address = ''
  occupation = ''
  occupation2 = '';
  occupations: string[] = [
    'Engineer',
    'Doctor',
    'Teacher',
    'Artist',
    'Lawyer',
    'Architect',
    'Nurse',
    'Scientist',
    'Accountant',
    'Software Developer',
    'Business Analyst',
    'Pharmacist',
    'Mechanic',
    'Pilot',
    'Dentist',
    'Electrician',
    'Plumber',
    'Chef',
    'Journalist',
    'Graphic Designer',
    'Civil Servant',
    'Psychologist',
    'Data Scientist',
    'Marketing Specialist',
    'Social Worker',
    'Veterinarian',
    'Physical Therapist',
    'HR Manager',
    'Entrepreneur',
    'Police Officer',
    'Firefighter',
    'Other'
  ];
  org_name = ''
  org_location = ''
  org_mail = ''
  org_digi = ''
  all_list = [];
  child_ben;
  spouse_basic;
  guardian;
  trustee;
  hide = 'yes'
  spouses;
  children_list;
  dependants;

  @Output() callParent = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    public beneficiary_service: BeneficiariesService,
    public spousalService: SpousalService,
    public childrenService: ChildrenService,
    private db: AngularFirestore,
    public dependants_service: DependantsService,
  ) { }

  ngOnInit() {
    this.fourthFormGroup = this._formBuilder.group({
      first_name: [""],
      mid_name: [""],
      last_name: [""],
      relationship: [""],
      res_address: [""],
      mail_address: [""],
      digi_address: ['', Validators.compose([AddressValidator.isValid])],
      person_org: [""],
      occupation: [""],
      occupation2: [""],
      org_name: [""],
      org_location: [""],
      org_mail: [""],
      org_digi: ['', Validators.compose([AddressValidator.isValid])],
    });



    this.beneficiary_service
      .retrieve_beneficiaries()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];

        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id
      

          this.first_name = data.data.first_name
          this.mid_name = data.data.mid_name
          this.last_name = data.data.last_name
          this.relationship = data.data.relationship
          this.res_address = data.data.res_address
          this.mail_address = data.data.mail_address
          this.digi_address = data.data.digi_address
          this.person_org = data.data.person_org
          this.occupation = data.data.occupation
          this.occupation2 = data.data.occupation2
          this.org_name = data.data.org_name
          this.org_location = data.data.org_location
          this.org_mail = data.data.org_mail
          this.org_digi = data.data.org_digi

      


        }
      })
    
    
    this.spousalService
      .retrieve_spousal_as_dependant_no_instructions()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.spouses = basic
        let data = basic[0];


        for (var name of this.spouses) {
          console.log("spouses NAME", name);
          this.all_list.push({
            name: name.data.spouse_first_name + " " + name.data.spouse_l_name
          });
        }
        console.log("spouses all_list LISTSS::: ", this.all_list);


      })

    this.spousalService
      .retrieve_spousal_as_beneficiary()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.spouses = basic
        let data = basic[0];


        for (var name of this.spouses) {
          console.log("spouses NAME", name);
          this.all_list.push({
            name: name.data.spouse_first_name + " " + name.data.spouse_l_name
          });
        }

      })

    // .retrieve_child_as_beneficiary()
    this.childrenService
      .retrieve_child_as_beneficiary()
      .subscribe((basic) => {
        console.log("CHILDREN CHECK BASIC REQUIR:::", basic)
        this.children_list = basic
        let data = basic[0];

        for (var name of this.children_list) {
          console.log("children_list NAME", name);
          this.all_list.push({
            name: name.data.c_first_name + " " + name.data.c_last_name
          });
        }
        console.log("children_list all_list LISTSS::: ", this.all_list);

      })


    this.childrenService
      .retrieve_child_as_dependant()
      .subscribe((basic) => {
        console.log("CHILDREN CHECK BASIC REQUIR:::", basic)
        this.children_list = basic
        let data = basic[0];

        for (var name of this.children_list) {
          console.log("children_list NAME", name);
          this.all_list.push({
            name: name.data.c_first_name + " " + name.data.c_last_name
          });
        }
        console.log("children_list all_list LISTSS::: ", this.all_list);

      })


    // retrieve_dependant_no_instructions
    this.dependants_service
      .retrieve_dependant_no_instructions()
      .subscribe((basic2) => {
        console.log("CHECK BASIC REQUIR:::", basic2)
        this.dependants = basic2

        for (var name of this.dependants) {
          console.log("dependants NAME", name);
          this.all_list.push({
            name: name.data.d_first_name + " " + name.data.d_last_name
          });
        }
        console.log("dependants all_list LISTSS::: ", this.all_list);


  
      })

    this.beneficiary_service
      .retrieve_trustee_as_beneficiary()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
     
        let data = basic[0];

        this.trustee = basic

        for (var name of this.trustee) {
          console.log("dependants NAME", name);
          this.all_list.push({
            name: name.data.f_name + " " + name.data.l_name
          });
        }
        console.log("dependants all_list LISTSS::: ", this.all_list);
      })

    // retrieve_guardian_as_beneficiary()
    this.beneficiary_service
      .retrieve_guardian_as_beneficiary()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.guardian = basic
        let data = basic[0];

        for (var name of this.guardian) {
          console.log("dependants NAME", name);
          this.all_list.push({
            name: name.data.f_name + " " + name.data.l_name
          });
        }


      })

    console.log("WHAT IN all_list", this.all_list)

  
  }



  public modelChange(str: string) {

  }

  all_forms() {
    this.completed = true;
    this.state = "done";
    console.log(this.firstFormGroup.value);
    console.log(this.secondFormGroup.value);
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
  }




  newProperty2() {

   // this.fourthFormGroup.reset()
    this.showSave = "yes"
    this.new_form = 'yes'

    this.hide = "no"
    this.first_name = ''
    this.mid_name = ''
    this.last_name = ''
    this.relationship = ''
    this.res_address = ''
    this.mail_address = ''
    this.digi_address = ''
    this.occupation = ''
    this.occupation2 = ''
    this.org_name = ''
    this.org_location = ''
    this.org_mail = ''
    this.org_digi = ''
    this.person_org = ''
  }




  edit_form(data) {
    this.new_form = 'yes'
    this.editing = 'yes'
    this.showSave = "no"
    this.hide = 'no'

    this.beneficiary_service
      .retrieve_beneficiaries_edit(data)
      .subscribe((basic) => {
        console.log("CHILD SERVICE:::", basic)
        console.log("DEPEM SERVICE DATA ID:::", basic.id)

        this.basic_doc_id = basic.id


        this.first_name = basic.data.first_name
        this.mid_name = basic.data.mid_name
        this.last_name = basic.data.last_name
        this.relationship = basic.data.relationship
        this.res_address = basic.data.res_address
        this.mail_address = basic.data.mail_address
        this.digi_address = basic.data.digi_address
        this.person_org = basic.data.person_org
        this.occupation = basic.data.occupation
        this.occupation2 = basic.data.occupation2
        this.org_name = basic.data.org_name
        this.org_location = basic.data.org_location
        this.org_mail = basic.data.org_mail
        this.org_digi = basic.data.org_digi


      })
  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("beneficiaries")
          .doc(data.id)
          .delete()
      });

    }
  }


  customFormatValidator() {
    // return (control) => {
    //   const value = control.value;
    //   const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(value);

    //   return validFormat ? null : { customFormat: true };
    // };

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === 'string') {
        const valueWithoutSpaces = control.value.replace(/\s/g, ''); // Remove whitespace
        const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(valueWithoutSpaces); // Validate format

        return validFormat ? null : { customFormat: true };
      }

      return { customFormat: true };
    };
  }



  save_trust() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.save_dependant(formData)

    this.new_form = 'no'
    this.callParent.emit("completed8");

  }
  otherDependant() {
    this.hide = "no"
  }


  update() {
    let formData: any = this.fourthFormGroup.value as {};
    console.log("VALUE ", formData);

    this.beneficiary_service.update_dependant(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed8");
  }



}
