import { analyzeAndValidateNgModules, Type } from "@angular/compiler";
import { Component, HostListener, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatRadioModule } from "@angular/material/radio";
import jsPDF from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import { PageEvent } from "@angular/material/paginator";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { PersonalDetailsService } from "../../shared/services/personal-details.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AddressValidator } from "src/validators/digital";


@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.css']
})
export class PersonalDetailsComponent implements OnInit {
  firstFormGroup: FormGroup;
  public searchStr: string = "";
  public age;
  anothername: string = "";
  residential: string = "";
  public mid: string = "";
  lname = '';
  occupation = '';
  occupation2 = '';
  mail = '';
  digital = '';
  legalDocsname = '';
  gender = '';
  first_name = '';
  legalDocsMname = '';
  legalDocsLname = '';
  legalDoc = '';


  new_form = 'no';
  editing = 'no';
  basic_doc_id;
  basic;
  show_realtime = 'no'

  firstname = '';
  mid_name = '';
  
  occupations: string[] = [
    'Engineer',
    'Doctor',
    'Teacher',
    'Artist',
    'Lawyer',
    'Architect',
    'Nurse',
    'Scientist',
    'Accountant',
    'Software Developer',
    'Business Analyst',
    'Pharmacist',
    'Mechanic',
    'Pilot',
    'Dentist',
    'Electrician',
    'Plumber',
    'Chef',
    'Journalist',
    'Graphic Designer',
    'Civil Servant',
    'Psychologist',
    'Data Scientist',
    'Marketing Specialist',
    'Social Worker',
    'Veterinarian',
    'Physical Therapist',
    'HR Manager',
    'Entrepreneur',
    'Police Officer',
    'Firefighter',
    'Other'
  ];

 
  @Output() callParent = new EventEmitter();
  constructor(private _formBuilder: FormBuilder, public personDetails: PersonalDetailsService, private db: AngularFirestore) {

  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      first_name: ["", Validators.required],
      mid_name: [""],
      lname: ["", Validators.required],
      occupation: [""],
      occupation2: [""],
      residential: [""],
      mail: [""],
      digital: ['', Validators.compose([Validators.required, AddressValidator.isValid])],
     // legalDoc: [""],
      // legalDocsname: [""],
      // legalDocsMname: [""],
      // legalDocsLname: [""],
      gender: ["", Validators.required],
    });


    //RETRIEVE PATINE DETAILS
    this.personDetails
      .retrieve_personal()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];
        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id
          this.firstname = data.data.first_name
          this.age = data.data.age
          this.mid_name = data.data.mid_name
          this.lname = data.data.lname
          this.occupation = data.data.occupation
          this.occupation2 = data.data.occupation2
          this.residential = data.data.residential
          this.mail = data.data.mail
          this.digital = data.data.digital
          // this.legalDoc = data.data.legalDoc
          // this.legalDocsname = data.data.legalDocsname
          // this.legalDocsMname = data.data.legalDocsMname
          // this.legalDocsLname = data.data.legalDocsLname
          this.gender = data.data.gender

          console.log("firstname:::", this.firstname)

        }
      })
  }


  customFormatValidator() {
    // return (control) => {
    //   const value = control.value;
    //   const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(value);

    //   return validFormat ? null : { customFormat: true };
    // };

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && typeof control.value === 'string') {
        const valueWithoutSpaces = control.value.replace(/\s/g, ''); // Remove whitespace
        const validFormat = /^[A-Z]{2}-\d{3,4}-\d{3,4}$/.test(valueWithoutSpaces); // Validate format

        return validFormat ? null : { customFormat: true };
      }

      return { customFormat: true };
    };
  }

  edit_form() {
    this.new_form = 'yes'
    this.editing = 'yes'
  }

  deleteForm(data) {
    console.log("DELETE VALUE ", data);
    console.log("DELETE ID ", data.id);


    let userData = JSON.parse(localStorage.getItem("user"));
    let userUid = userData.uid


    if (userUid) {
      console.log("USER EXISTS--")
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("person_details")
          .doc(data.id)
          .delete()
      });

    }
  }



  save_person() {
    let formData: any = this.firstFormGroup.value as {};
    console.log("VALUE ", formData);

    this.personDetails.save_personal(formData)

    this.new_form = 'no'
    

    this.callParent.emit("completed");

  }


  update() {
    let formData: any = this.firstFormGroup.value as {};
    console.log("updayed VALUE ", formData);

    this.personDetails.update_personal(this.basic_doc_id, formData)

    this.new_form = 'no'
    this.editing = 'no'

    this.callParent.emit("completed");

  }

  ngAfterViewInit() {
    //RETRIEVE PATINE DETAILS
    this.personDetails
      .retrieve_personal()
      .subscribe((basic) => {
        console.log("CHECK BASIC REQUIR:::", basic)
        this.basic = basic
        let data = basic[0];
        console.log("LENGHT:::", this.basic.length)

        if (basic.length > 0) {
          let data = basic[0];
          this.basic_doc_id = data.id
          this.firstname = data.data.first_name
          this.age = data.data.age
          this.mid_name = data.data.mid_name
          this.lname = data.data.lname
          this.occupation = data.data.occupation
          this.occupation2 = data.data.occupation2
          this.residential = data.data.residential
          this.mail = data.data.mail
          this.digital = data.data.digital
          //this.legalDoc = data.data.legalDoc
          // this.legalDocsname = data.data.legalDocsname
          // this.legalDocsMname = data.data.legalDocsMname
          // this.legalDocsLname = data.data.legalDocsLname
          this.gender = data.data.gender

          console.log("firstname:::", this.firstname)

        }
      })
    
  }



  public modelChange(str: string) {
    this.show_realtime = 'yes'
    //FIRST FORM GROUP == PERSONAL
    // this.searchStr = this.firstFormGroup.value.first_name;
    // this.mid = this.firstFormGroup.value.mid_name;
    // this.lname = this.firstFormGroup.value.lname;
    // this.gender = this.firstFormGroup.value.gender;
    // this.occupation = this.firstFormGroup.value.occupation;
    // this.residential = this.firstFormGroup.value.residential;
    // this.mail = this.firstFormGroup.value.mail;
    // this.digital = this.firstFormGroup.value.digital;
    // this.anothername = this.firstFormGroup.value.anothername;
    // this.legalDocsname = this.firstFormGroup.value.legalDocsname;

 

    // this.callParent.emit(this.searchStr);
    // this.callParent.emit();
    // this.callParent.emit(this.lname);
   

  }

}
